// MenuItemLink.js
import Link from "next/link";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import { MenuItem } from "react-pro-sidebar";

interface Props {
  href?: string;
  label: string;
  icon?: ReactNode;
  activePath?: string;
  onClick?: () => void;
  className?: string;
}

const MenuItemLink = ({
  href,
  label,
  icon,
  activePath,
  onClick,
  className,
}: Props) => {
  const router = useRouter();

  const linkTo = (href: string) => {
    return <Link href={`/${router.query.hotelId}${href}`} />;
  };

  return (
    <MenuItem
      component={linkTo(href || "")}
      icon={icon}
      active={router.pathname === (activePath ?? `/[hotelId]${href}`)}
      onClick={onClick}
      className={className}
    >
      {label}
    </MenuItem>
  );
};

export default MenuItemLink;
