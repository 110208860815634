globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"96073c78770964eb6d1b2432b711d66366e4daf1"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { isDev } from "~/utils/nextjs-utils";

Sentry.init({
  enabled: !isDev(),
  // enabled: true,
  dsn: "https://1c3ea0ddcdc24d0d70fa222f57f8f7f5@o4504786431246336.ingest.sentry.io/4506648286920704",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  // debug: true,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  normalizeDepth: 6,

  // autoSessionTracking: true,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    // new Sentry.Feedback({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: "light",
    // }),

    Sentry.browserTracingIntegration(),

    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
});
