import {
  SearchSelect,
  SearchSelectItem,
  Select,
  SelectItem,
} from "@tremor/react";
import { useRouter } from "next/router";
import { api } from "~/utils/api";

interface Props {
  value: string | undefined;
  isAdmin: boolean;
}

export const SelectHotelHeader = ({ value, isAdmin }: Props) => {
  const router = useRouter();
  const { data, isLoading } = api.reports.getHotelList.useQuery(undefined, {
    placeholderData: (prev) => prev ?? [],
  });

  const onValueChange = async (value: string) => {
    // router.pathname;

    const url = router.pathname.replace("[hotelId]", value);
    console.log("onValueChange", {
      url,
      value,
      p: router.pathname,
      router,
    });

    // save the hotelId in the session
    window.sessionStorage.setItem("hotelId", value);
    window.localStorage.setItem("hotelId", value);

    await router.push(url);
    console.log("muly:hotelselect:onValueChange: after push", {
      url,
      value,
      p: router.pathname,
      router,
    });
  };

  if (!isLoading && !data?.length) {
    return <div>No hotels, please contact support to allow access</div>;
  }

  if (isAdmin) {
    return (
      <div>
        <SearchSelect
          value={value}
          onValueChange={onValueChange}
          className="max-w-fit"
          disabled={!data}
          enableClear={false}
        >
          {(data ?? [])
            .filter(({ isGroup }) => !isGroup)
            .map(({ id, name, isGroup }) => (
              <SearchSelectItem key={id} value={id}>
                {name}
              </SearchSelectItem>
            ))}
        </SearchSelect>
      </div>
    );
  } else {
    if ((data?.length ?? 0) > 1) {
      return (
        <Select
          value={value}
          onValueChange={onValueChange}
          className="max-w-fit"
          disabled={!data}
          enableClear={false}
        >
          {(data ?? [])
            .filter(({ isGroup }) => !isGroup)
            .map(({ id, name, isGroup }) => (
              <SelectItem key={id} value={id}>
                {name}
              </SelectItem>
            ))}
        </Select>
      );
    } else {
      return null;
    }
  }
};
