/* eslint-disable @typescript-eslint/no-unsafe-call */

import Dexie from "dexie";
import { useCallback, useEffect, useState } from "react";
import type { ReportsData } from "~/server/db-types";
import { trpcVanillaClient } from "~/utils/api";
import { castError } from "~/utils/errors";

const pageSize = 1000;
const db = new Dexie("report-data-db");
db.version(1).stores({
  hotels: "&id",
  reservations: "&id, hotelId",
});

export const clearLocalDB = async (hotelId: string): Promise<void> => {
  // @ts-ignore
  await db.reservations.where("hotelId").equals(hotelId).delete();
  // @ts-ignore
  await db.hotels.where("id").equals(hotelId).delete();
};

export const syncLocalDB = async (
  hotelId: string,
  setHotelDataDownloadProgress: ProgressCallback,
): Promise<boolean> => {
  // read hotel data + reservation data from API, based on last sync date

  let lastSyncTimestamp = 0;
  // @ts-ignore
  const savedHotel = await db.hotels.get(hotelId);
  if (savedHotel) {
    lastSyncTimestamp = savedHotel.lastSyncTimestamp;
    console.log(
      `muly:syncLocalDB:lastSyncTimestamp ${new Date(lastSyncTimestamp)}`,
      { savedHotel },
    );
  }

  let hasChanges = false;
  let continueSync = true;
  let loopCount = 0;
  let hotel: ReportsData["hotel"] | null = null;

  // we can have partial sync reservations but hotel was not yet saved, sw we need all new reservations
  let reservationDbCount = savedHotel
    ? // @ts-ignore
      await db.reservations.where("hotelId").equals(hotelId).count()
    : 0;

  while (continueSync && loopCount < 1000) {
    loopCount++;
    let reservations: ReportsData["reservations"] = [];
    if (!hotel) {
      let fullQuery;
      try {
        fullQuery = await trpcVanillaClient.reports.getReportData.query({
          hotelId,
          lastSyncDate: lastSyncTimestamp ? new Date(lastSyncTimestamp) : null,
          limit: pageSize,
        });
      } catch (e) {
        const error = castError(e);
        console.log(`muly:local-db:syncLocalDB ${error.message}`, {
          error: error.stack,
        });

        window.location.href = "/";
        throw error;
      }

      hotel = fullQuery.hotel;
      reservations = fullQuery.reservations;

      if (
        lastSyncTimestamp &&
        savedHotel &&
        hotel.resetLocalDB !== savedHotel.resetLocalDB
      ) {
        // reset local db
        // @ts-ignore
        await db.reservations.where("hotelId").equals(hotelId).delete();
        reservationDbCount = 0;

        // so we don't reset again in a loop
        savedHotel.resetLocalDB = hotel.resetLocalDB;

        // reread all reservations
        lastSyncTimestamp = 0;
        hasChanges = true;

        // notify reset
        setHotelDataDownloadProgress(-1);
        continue;
      }
    } else {
      const partialQuery =
        await trpcVanillaClient.reports.getReportDataPartial.query({
          hotelId,
          lastSyncDate: lastSyncTimestamp ? new Date(lastSyncTimestamp) : null,
          limit: pageSize,
        });

      reservations = partialQuery.reservations;
    }

    console.log(
      `syncLocalDB:reservations ts:${lastSyncTimestamp} count:${hotel.totalReservationCount} batch:${reservations.length}`,
      {},
    );

    if (reservations.length > 0) {
      // Save reservation data to local indexedDB
      // @ts-ignore
      const answer = await db.reservations.bulkPut(reservations);
      reservationDbCount += reservations.length;
      // console.log(`muly:syncLocalDB save reservations`, {
      //   reservations,
      //   answer,
      // });

      reservations.forEach(({ id, changedAt }) => {
        if (!lastSyncTimestamp || changedAt.getTime() > lastSyncTimestamp) {
          lastSyncTimestamp = changedAt.getTime();
        }
      });
      hasChanges = true;

      // console.log(
      //   `local-db:setHotelDataDownloadProgress ${
      //     reservationDbCount / hotel.totalReservationCount
      //   }`,
      //   {
      //     reservationDbCount,
      //     count: hotel.totalReservationCount,
      //     hotel,
      //   },
      // );
      setHotelDataDownloadProgress(
        reservationDbCount / hotel.totalReservationCount,
      );
    }

    continueSync = reservations.length === pageSize;
    if (!continueSync) {
      // Save hotel data to local indexedDB
      // @ts-ignore
      await db.hotels.put({ ...hotel, lastSyncTimestamp });

      if (hotel.totalReservationCount === 0) {
        hasChanges = true;
      }
      console.log(`muly:syncLocalDB:finished sync`, {
        hotel,
        lastSyncTimestamp,
      });
    }
  }

  return hasChanges;
};

export const getHotelDataFromLocalDB = async (
  hotelId: string,
): Promise<ReportsData> => {
  const [hotel, reservations] = await Promise.all([
    // @ts-ignore
    db.hotels.get(hotelId),
    // @ts-ignore
    db.reservations.where("hotelId").equals(hotelId).toArray(),
  ]);
  return { hotel, reservations };
};

export interface ReportsDataHook {
  data: ReportsData;
  isLoading: boolean;
  isReady: boolean;
  reload: () => Promise<void>;
}

type ProgressCallback = (progress: number) => void;

export const useGetHotelReservationData = (
  hotelId: string | undefined,
  userProfileId: string | undefined,
  setHotelDataDownloadProgress: ProgressCallback,
): ReportsDataHook => {
  const [data, setData] = useState<ReportsData>({
    hotel: {
      id: "",
      name: "",
      roomTypes: [],
      currencyCode: "",
      currencyPosition: "before",
      currencySymbol: "-",
      resetLocalDB: 0,
      totalReservationCount: 0,
      providerName: "",
      timezone: "Europe/London",
      owner: "",
      missingConfiguration: false,
      isFreeTrial: false,
      blockUsage: false,

      curves: {
        id: "",
        curveValues: [],
      },
    },
    reservations: [],
  });
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);

  const localSetHotelDataDownloadProgress = useCallback(
    (progress: number) => {
      if (progress === -1) {
        setReady(false);
        setLoading(true);
        setHotelDataDownloadProgress(0);
      } else {
        setHotelDataDownloadProgress(progress);
      }
    },
    [setHotelDataDownloadProgress],
  );

  useEffect(() => {
    localSetHotelDataDownloadProgress(-1);
    const sync = async () => {
      if (!hotelId || !userProfileId) return;

      const data = await getHotelDataFromLocalDB(hotelId);
      setData(data);
      // hotel-data-stats we have 0 reservations, but we still want to show the hotel data
      if (
        data.hotel &&
        data.reservations /*.length > 0*/ &&
        // @ts-ignore
        data.hotel.lastSyncTimestamp > Date.now() - 1000 * 60 * 60 * 24 * 4
      ) {
        // console.log(`muly:local-db-effect:found data`, { data });
        setReady(true);
      }

      const change = await syncLocalDB(
        hotelId,
        localSetHotelDataDownloadProgress,
      );
      if (change) {
        const data = await getHotelDataFromLocalDB(hotelId);
        setData(data);
        setReady(true);
        setLoading(false);
      }
    };

    sync().catch((err) => {
      console.error("useGetHotelReservationData", err);
    });
  }, [hotelId, userProfileId, localSetHotelDataDownloadProgress]);

  const reload = async () => {
    if (!hotelId) return;
    localSetHotelDataDownloadProgress(-1);
    const change = await syncLocalDB(
      hotelId,
      localSetHotelDataDownloadProgress,
    );
    if (change) {
      const data = await getHotelDataFromLocalDB(hotelId);
      setData(data);
      setReady(true);
      setLoading(false);
    }
  };

  return { data, isLoading: loading, isReady: ready, reload };
};
