import Link from "next/link";
import type { ReactNode } from "react";
import { useState } from "react";
import { useConfigContext } from "~/components/common/config/config-context";
import { ExpireMessage } from "~/components/common/ui/ExpireMessage";
import { Footer } from "~/components/layout/Footer";
import { Header } from "~/components/layout/Header";
import { SidebarMenu } from "~/components/layout/SidebarMenu";
import { Toaster } from "~/components/ui/toaster";
import { cn } from "~/lib/utils";

export const Layout = ({ children }: { children: ReactNode }) => {
  // @ts-ignore
  const pageDisplayName = children?.type.displayName ?? "-";

  const { userProfile, hotelData, hotelId } = useConfigContext();
  const [collapsedSidebar, setCollapsedSidebar] = useState(true);

  const handleMenuItemClick = () => {
    if (window.innerWidth < 768) {
      setCollapsedSidebar(true);
    }
  };

  const handleMouseOver = () => {
    setCollapsedSidebar(false);
  };

  const handleMouseOut = () => {
    setCollapsedSidebar(true);
  };

  const missingConfiguration = hotelData.data?.hotel?.missingConfiguration;
  const daysLeft = hotelData.data?.hotel?.daysLeft ?? 0;
  const isFreeTrial = hotelData.data?.hotel?.isFreeTrial;
  const showBanner =
    missingConfiguration ||
    (daysLeft !== null && daysLeft !== undefined && daysLeft <= 7);

  const blockUsage =
    hotelData.data?.hotel?.blockUsage &&
    // @ts-ignore
    !children?.type?.disableNeedSubscription;

  const bannerText = blockUsage
    ? isFreeTrial
      ? "Free trial ended"
      : "Subscription ended"
    : missingConfiguration
      ? `Missing minimum rates configuration`
      : isFreeTrial
        ? `Free trial ends${daysLeft >= 0 ? ` in ${daysLeft} days` : ""}`
        : `Subscription ends${daysLeft >= 0 ? ` in ${daysLeft} days` : ""}`;

  const adminAction = missingConfiguration
    ? { href: `/${hotelId}/settings`, text: "Configure Now" }
    : { href: `/${hotelId}/subscription`, text: "Subscribe Now" };

  const showAdminAction =
    !blockUsage &&
    (userProfile?.role === "ADMIN" ||
      (userProfile?.email &&
        hotelData.data.hotel?.owner?.includes(userProfile.email)));

  if (userProfile) {
    return (
      <div className="flex h-screen flex-row">
        <SidebarMenu
          collapsed={collapsedSidebar}
          userProfile={userProfile}
          onMenuItemClick={handleMenuItemClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
        <div
          className={cn(
            "scrollbar-thin relative flex w-full flex-col overflow-x-hidden bg-white",
            { "ml-[80px]": window.innerWidth > 768 },
          )}
        >
          {showBanner && (
            <div className="m-2 rounded-lg bg-status-lightRed p-4 text-white">
              {bannerText}
              {!!showAdminAction && (
                <>
                  <span>, </span>
                  <Link href={adminAction.href} className="underline">
                    {adminAction.text}
                  </Link>
                </>
              )}
            </div>
          )}
          <Header
            hotelId={hotelId || ""}
            hotelName={hotelData?.data?.hotel?.name ?? "-"}
            pageDisplayName={pageDisplayName}
            onSidebarToggleClick={() => setCollapsedSidebar(!collapsedSidebar)}
            userProfile={userProfile}
            className={cn({ "top-16": showBanner })}
          />
          <main className="m-4 mt-16">
            {blockUsage ? <ExpireMessage title={bannerText} /> : children}
          </main>
          <div className="flex-1">
            <div className="flex h-full items-end">
              <Footer />
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    );
  }

  return children;
};
