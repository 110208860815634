import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { useConfigContext } from "~/components/common/config/config-context";
import { ShortMessage } from "~/components/common/ui/ShortMessage";
import { Button } from "~/components/ui/button";

interface Props {
  onReload: () => void;
  error: Error;
}

export const ErrorBoundaryComponent = ({ onReload, error }: Props) => {
  const { userProfile, hotelId } = useConfigContext();
  const router = useRouter();
  const supabase = useSupabaseClient();

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
    } else {
      // await router.replace("/login");
      router.reload();
    }
  };

  const logoutOption = error.message.includes(" (C:6");
  return (
    <ShortMessage title="Error" text={error.message} icon="error">
      <div className="flex flex-row gap-4">
        {logoutOption && (
          <Button
            variant="destructive"
            onClick={logout}
            className="mx-auto w-fit px-14"
          >
            Logout
          </Button>
        )}
        <Button
          variant="primary"
          onClick={onReload}
          className="mx-auto w-fit px-14"
        >
          Reload
        </Button>
      </div>
      <div>
        <pre className="self-start text-left text-xs text-slate-400">
          {JSON.stringify(
            {
              id: userProfile?.id,
              email: userProfile?.email,
              hotelId,
            },
            null,
            2,
          )}
        </pre>
      </div>
    </ShortMessage>
  );
};
