import { Button } from "@tremor/react";
import { ChevronRight } from "lucide-react";
import { SelectHotelHeader } from "~/components/hotels/select-hotel-header";
import { TopbarIcon } from "~/components/landing-page/parts/Icons";
import { Breadcrumbs } from "~/components/layout/Breadcrumbs";
import { cn } from "~/lib/utils";
import type { UserProfile } from "~/server/db-types";

interface HeaderProps {
  hotelId: string;
  hotelName: string;
  pageDisplayName: string;
  onSidebarToggleClick: () => void;
  userProfile: UserProfile;
  className?: string;
}

export const Header = ({
  hotelId,
  pageDisplayName,
  hotelName,
  onSidebarToggleClick,
  userProfile,
  className,
}: HeaderProps) => {
  // const { theme, setTheme } = useTheme();

  // console.log("theme" + JSON.stringify(theme));
  const isAdmin = userProfile.role === "ADMIN";

  return (
    <div
      className={cn(
        "absolute top-0 flex w-full flex-row items-center justify-between gap-3 p-4",
        className,
      )}
    >
      {/* TODO: replace with icon */}
      <Button
        icon={TopbarIcon}
        variant="light"
        onClick={onSidebarToggleClick}
      />
      <ChevronRight size={22} />
      <Breadcrumbs part1={hotelName} part2={pageDisplayName} />
      <SelectHotelHeader value={hotelId} isAdmin={isAdmin} />
      {/*<Button*/}
      {/*  icon={SunIcon}*/}
      {/*  variant="light"*/}
      {/*  onClick={() => setTheme(theme === "light" ? "dark" : "light")}*/}
      {/*/>*/}
    </div>
  );
};
