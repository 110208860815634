import { ChevronRight } from "lucide-react";

interface Props {
  part1: string;
  part2: string;
}

export const Breadcrumbs = ({ part1, part2 }: Props) => {
  return (
    <div className="flex w-full flex-row">
      <div className="whitespace-nowrap">{part1}</div>
      <div className="mx-2 flex items-center">
        <ChevronRight size={16} />
      </div>
      <div className="whitespace-nowrap">{part2}</div>
    </div>
  );
};
