import { useRouter } from "next/router";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { cn } from "~/lib/utils";
// import { useTheme } from "next-themes";
import { FeedbackDialog } from "~/components/common/ui/FeedbackDialog";
import {
  ActiveSubMenuIcon,
  FeedbackIcon,
  HomepageIcon,
  LogoIcon,
  PricesIcon,
  ReportsIconSidebar,
  SubscriptionIcon,
} from "~/components/landing-page/parts/Icons";
import MenuItemLink from "~/components/layout/MenuItemLink";
import type { UserProfile } from "~/server/db-types";

interface SidebarMenuProps {
  collapsed: boolean;
  userProfile: UserProfile;
  onMenuItemClick: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
}

const menuItemStyles = {
  // @ts-ignore
  button: ({ level, active }) =>
    active && level === 0
      ? {
          backgroundColor: "#2c2b6e",
          marginLeft: "20px",
          marginRight: "20px",
          paddingLeft: "5px",
          paddingRight: "10px",
          borderRadius: "8px",
          color: "white",
          "&:hover": {
            backgroundColor: "#2c2b6e",
          },
        }
      : !active && level === 0
        ? {
            marginLeft: "20px",
            marginRight: "20px",
            paddingLeft: "5px",
            paddingRight: "10px",
            borderLeft: "5px solid hsl(var(--sidebarBackground))",
            color: "white",
            "&:hover": {
              backgroundColor: "hsl(var(--sidebarBackground))",
              borderLeft: "5px solid white",
            },
          }
        : active && level === 1
          ? {
              // borderLeft: "5px solid white",
              // marginLeft: "25px",
              marginRight: "25px",
              paddingLeft: "20px",
              // paddingRight: "10px",
              marginTop: "5px",
              color: "white",
              "&:hover": {
                backgroundColor: "hsl(var(--sidebarBackground))",
              },
            }
          : !active && level === 1
            ? {
                marginLeft: "25px",
                marginRight: "25px",
                // paddingLeft: "20px",
                paddingRight: "0px",
                marginTop: "5px",
                // borderLeft: "5px solid hsl(var(--sidebarBackground))",
                color: "white",
                "&:hover": {
                  backgroundColor: "hsl(var(--sidebarBackground))",
                  borderLeft: "5px solid white",
                },
              }
            : {},
};

export const SidebarMenu = ({
  userProfile,
  collapsed,
  onMenuItemClick,
  onMouseOver,
  onMouseOut,
}: SidebarMenuProps) => {
  const router = useRouter();
  // const { theme } = useTheme();

  const isAdmin = userProfile.role === "ADMIN";

  function GetActiveSubMenuIcon(href: string) {
    return router.pathname === `/[hotelId]${href}` ? <ActiveSubMenuIcon /> : "";
  }

  function CheckActiveMenuItem(href: string) {
    return router.pathname === `/[hotelId]${href}`;
  }

  return (
    <Sidebar
      collapsed={collapsed}
      collapsedWidth={`${window.innerWidth < 768 ? 0 : 80}px`}
      className={cn("!absolute z-50 h-screen")}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Menu
        className={cn(
          "h-full min-h-full bg-sidebarBackground px-0 py-5 text-sidebarText [&>ul>li>a]:!outline-none [&>ul>li>a]:!ring-0 [&>ul]:flex [&>ul]:h-full [&>ul]:flex-col [&>ul]:justify-between",
        )}
        menuItemStyles={menuItemStyles}
      >
        <div>
          <MenuItemLink
            href="/"
            icon={<LogoIcon />}
            onClick={onMenuItemClick}
            label="Rate Quantum"
            className={cn(
              "mb-5 text-2xl font-bold text-white",
              {
                "[&>a]:!m-0": !collapsed,
              },
              {
                "-ml-2.5": collapsed,
              },
            )}
          />
          <MenuItemLink
            href="/"
            icon={<HomepageIcon />}
            activePath="/[hotelId]"
            onClick={onMenuItemClick}
            label="Home"
            className={cn(
              {
                "-ml-2.5": collapsed,
              },
              {
                "[&>a]:!ml-[24px] [&>a]:w-[46px]":
                  collapsed && CheckActiveMenuItem(""),
              },
            )}
          />
          <SubMenu
            label="Prices"
            icon={<PricesIcon />}
            className={cn(
              "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
              {
                "-ml-2.5 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
              },
              {
                "[&>a]:!ml-[24px] [&>a]:w-[46px] [&>a]:bg-[#2c2b6e]":
                  collapsed &&
                  (router.pathname === "/[hotelId]/rate-control" ||
                    router.pathname === "/[hotelId]/rq-dashboard" ||
                    router.pathname === "/[hotelId]/settings"),
              },
            )}
            active={
              router.pathname === "/[hotelId]/rate-control" ||
              router.pathname === "/[hotelId]/rq-dashboard" ||
              router.pathname === "/[hotelId]/settings"
            }
          >
            <MenuItemLink
              href="/rq-dashboard"
              icon={GetActiveSubMenuIcon("/rq-dashboard")}
              onClick={onMenuItemClick}
              label="Dashboard"
            />
            <MenuItemLink
              href="/rate-control"
              icon={GetActiveSubMenuIcon("/rate-control")}
              onClick={onMenuItemClick}
              label="Rate Control"
            />
            <MenuItemLink
              href="/settings"
              icon={GetActiveSubMenuIcon("/settings")}
              onClick={onMenuItemClick}
              label="Property Settings"
            />
          </SubMenu>
          {/*<MenuItemLink*/}
          {/*  icon={<ForecastingIcon />}*/}
          {/*  href="/forecasting"*/}
          {/*  onClick={onMenuItemClick}*/}
          {/*  label="Forecasting"*/}
          {/*  className={cn(*/}
          {/*    {*/}
          {/*      "-ml-2.5": collapsed,*/}
          {/*    },*/}
          {/*    {*/}
          {/*      "[&>a]:!ml-[24px] [&>a]:w-[46px]":*/}
          {/*        collapsed && CheckActiveMenuItem("/forecasting"),*/}
          {/*    },*/}
          {/*  )}*/}
          {/*/>*/}
          <SubMenu
            label="Reports"
            icon={<ReportsIconSidebar />}
            className={cn(
              "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
              {
                "-ml-2.5 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
              },
              {
                "[&>a]:!ml-[24px] [&>a]:w-[46px] [&>a]:bg-[#2c2b6e]":
                  collapsed &&
                  (router.pathname === "/[hotelId]/revenue" ||
                    router.pathname === "/[hotelId]/source" ||
                    router.pathname === "/[hotelId]/room-type" ||
                    router.pathname === "/[hotelId]/bookings"),
              },
            )}
            active={
              router.pathname === "/[hotelId]/revenue" ||
              router.pathname === "/[hotelId]/source" ||
              router.pathname === "/[hotelId]/room-type" ||
              router.pathname === "/[hotelId]/bookings"
            }
          >
            <MenuItemLink
              href="/revenue"
              icon={GetActiveSubMenuIcon("/revenue")}
              activePath="/[hotelId]/revenue"
              onClick={onMenuItemClick}
              label="Revenue"
            />
            <MenuItemLink
              href="/source"
              icon={GetActiveSubMenuIcon("/source")}
              onClick={onMenuItemClick}
              label="Source Report"
            />
            <MenuItemLink
              href="/room-type"
              icon={GetActiveSubMenuIcon("/room-type")}
              onClick={onMenuItemClick}
              label="Room Type Report"
            />
            <MenuItemLink
              href="/bookings"
              icon={GetActiveSubMenuIcon("/bookings")}
              onClick={onMenuItemClick}
              label="Booking Report"
            />
          </SubMenu>

          <div
            className={cn({
              "[&>button>li>a>.ps-menu-icon]:-ml-2.5 [&>button>li>a>.ps-menu-label]:hidden":
                collapsed,
            })}
          >
            <FeedbackDialog
              DialogTriggerElement={
                <MenuItem
                  icon={<FeedbackIcon />}
                  className="text-left focus-visible:outline-none"
                >
                  Feedback
                </MenuItem>
              }
            />
          </div>
          {isAdmin && (
            <>
              <div className="mt-2"></div>
              <SubMenu
                label="Admin"
                icon={<HomepageIcon />}
                className={cn(
                  "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
                  {
                    "-ml-2.5 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
                  },
                  {
                    "[&>a]:!ml-[24px] [&>a]:w-[46px] [&>a]:bg-[#2c2b6e]":
                      collapsed &&
                      (router.pathname === "/[hotelId]/engine-room" ||
                        router.pathname === "/[hotelId]/rate-ranges" ||
                        router.pathname === "/[hotelId]/revenues" ||
                        router.pathname === "/[hotelId]/dbg-reservations" ||
                        router.pathname === "/[hotelId]/cancellation-report" ||
                        router.pathname === "/[hotelId]/dbg-rates" ||
                        router.pathname === "/[hotelId]/hotel-data-stats"),
                  },
                )}
                active={
                  router.pathname === "/[hotelId]/engine-room" ||
                  router.pathname === "/[hotelId]/rate-ranges" ||
                  router.pathname === "/[hotelId]/revenues" ||
                  router.pathname === "/[hotelId]/dbg-reservations" ||
                  router.pathname === "/[hotelId]/cancellation-report" ||
                  router.pathname === "/[hotelId]/dbg-rates" ||
                  router.pathname === "/[hotelId]/hotel-data-stats"
                }
              >
                <MenuItemLink
                  href="/engine-room"
                  icon={GetActiveSubMenuIcon("/engine-room")}
                  onClick={onMenuItemClick}
                  label="Engine Room"
                />
                <MenuItemLink
                  href="/rate-ranges"
                  icon={GetActiveSubMenuIcon("/rate-ranges")}
                  onClick={onMenuItemClick}
                  label="Rate Ranges"
                />
                <MenuItemLink
                  href="/revenues"
                  icon={GetActiveSubMenuIcon("/revenues")}
                  onClick={onMenuItemClick}
                  label="Revenues"
                />
                <MenuItemLink
                  href="/dbg-reservations"
                  icon={GetActiveSubMenuIcon("/dbg-reservations")}
                  onClick={onMenuItemClick}
                  label="Reservations"
                />
                <MenuItemLink
                  href="/cancellation-report"
                  icon={GetActiveSubMenuIcon("/cancellation-report")}
                  onClick={onMenuItemClick}
                  label="Cancellation"
                />
                <MenuItemLink
                  href="/rate-change-history"
                  icon={GetActiveSubMenuIcon("/rate-change-history")}
                  onClick={onMenuItemClick}
                  label="Rate Change History"
                />
                <MenuItemLink
                  href="/dbg-rates"
                  icon={GetActiveSubMenuIcon("/dbg-rates")}
                  onClick={onMenuItemClick}
                  label="Published Rates"
                />
                <MenuItemLink
                  href="/hotel-data-stats"
                  icon={GetActiveSubMenuIcon("/hotel-data-stats")}
                  onClick={onMenuItemClick}
                  label="Data Stats"
                />
              </SubMenu>
              <div className="mt-4"></div>
            </>
          )}
        </div>

        <div>
          <hr className="mx-5 mb-3 border-t border-[#363495]" />
          <MenuItemLink
            icon={<SubscriptionIcon />}
            href="/subscription"
            onClick={onMenuItemClick}
            label="Subscription"
            className={cn(
              {
                "-ml-2.5": collapsed,
              },
              {
                "[&>a]:!ml-[24px] [&>a]:w-[46px]":
                  collapsed && CheckActiveMenuItem("/subscription"),
              },
            )}
          />
        </div>
      </Menu>
    </Sidebar>
  );
};
