import Link from "next/link";
import { useRouter } from "next/router";
import { ShortMessage } from "~/components/common/ui/ShortMessage";
import { Button } from "~/components/common/ui/button";

interface Props {
  title: string;
}

export const ExpireMessage = ({ title }: Props) => {
  const router = useRouter();
  const hotelId = router.query.hotelId as string;

  return (
    <ShortMessage
      icon="error"
      title={title}
      text="Please subscribe to continue use Rate Quantum"
    >
      <Link href={`/${hotelId}/subscription`}>
        <Button variant="primary">Subscribe</Button>
      </Link>
    </ShortMessage>
  );
};
